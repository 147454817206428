<template>
  <div>
    <div class="page-label">收支明细</div>
    <div class="asset-detail-wrap">
      <b-container fluid>
        <div class="user-asset-info">
          <div class="info-left">
            <div class="asset-label">
              <span>RMB</span>
            </div>
            <div class="total-account">
              <span>账户总额</span>
              <span>¥28000.00</span>
            </div>
            <div class="frozen-amount">
              <span>冻结金额</span>
              <span>¥0.00</span>
            </div>
            <div class="available-amount">
              <span>可用金额</span>
              <span>¥28000.00</span>
            </div>
            <div class="cash-out-btn">
              <span>提现</span>
            </div>
          </div>
          <div class="info-right">
            <div class="e-coin-detail">
              <div class="e-coin-icon">
                <img src="@/../public/icon/userCenter/assetDetails/e-coin-icon.png" alt="">
              </div>
              <div class="e-coin">
                <span>我的e币</span>
                <span>1350</span>
              </div>
            </div>
            <div class="operation">
              <span>购买</span>
              <span>兑换</span>
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <div class="select-items">
            <el-form :inline="true" ref="form" :model="form" label-width="80px">
              <el-form-item label="日期范围:">
                <el-date-picker
                  v-model="form.startDate"
                  type="date"
                  placeholder="开始日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-date-picker
                  v-model="form.endDate"
                  type="date"
                  placeholder="结束日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="关键词:">
                <el-input v-model="form.keywords" placeholder="订单号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button class="checked">查询</el-button>
              </el-form-item>
              <el-form-item label="交易类别:">
                <el-select v-model="form.transactionType">
                  <el-option
                    v-for="item in form.transactionTypeOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-tab-pane label="所有流水" name="first">
            <div class="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>交易时间</th>
                    <th>交易类别</th>
                    <th>交易金额</th>
                    <th>账户剩余</th>
                    <th>订单</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">加盟分成</div>
                    </td>
                    <td>
                      <div class="item">6866.00 ¥</div>
                    </td>
                    <td>
                      <div class="item">47616.00 ¥</div>
                    </td>
                    <td>
                      <div class="item order">子账号【Aurora】升级高级会员，确认加盟分成利润入账[子订单号=MYD2021083012345，入账金额6866.00元]</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">订单支付</div>
                    </td>
                    <td>
                      <div class="item deficit">-10808.00 ¥</div>
                    </td>
                    <td>
                      <div class="item">28000.00 ¥</div>
                    </td>
                    <td>
                      <div class="item order">订单支付[父订单号=MYD2021083012345]</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="支出明细" name="second">
            <div class="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>交易时间</th>
                    <th>交易类别</th>
                    <th>交易金额</th>
                    <th>账户剩余</th>
                    <th>订单</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">订单支付</div>
                    </td>
                    <td>
                      <div class="item deficit">-10808.00 ¥</div>
                    </td>
                    <td>
                      <div class="item">28000.00 ¥</div>
                    </td>
                    <td>
                      <div class="item order">订单支付[父订单号=MYD2021083012345]</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="收入明细" name="third">
            <div class="table-wrap">
              <table>
                <thead>
                  <tr>
                    <th>交易时间</th>
                    <th>交易类别</th>
                    <th>交易金额</th>
                    <th>账户剩余</th>
                    <th>订单</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="item time">2021-08-31 17:39</div>
                    </td>
                    <td>
                      <div class="item">加盟分成</div>
                    </td>
                    <td>
                      <div class="item">6866.00 ¥</div>
                    </td>
                    <td>
                      <div class="item">47616.00 ¥</div>
                    </td>
                    <td>
                      <div class="item order">子账号【Aurora】升级高级会员，确认加盟分成利润入账[子订单号=MYD2021083012345，入账金额6866.00元]</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="paging-wrap">
          <div class="paging-con">
            <el-pagination
              background
              layout="prev, pager, next, jumper"
              :pager-count="5"
              prev-text="上一页"
              next-text="下一页"
              :total="100">
            </el-pagination>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        activeName: 'first',
        form: {
          startDate: "",
          endDate: "",
          keywords: "",
          transactionTypeOptions: ["全部"],
          transactionType: "全部"
        }
      }
    },
    methods: {
      openWithdrawalWindow(){
        
      },
      handleClick(tab, event) {
        console.log(tab, event);
      }
      
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/assetManagement/assetDetails/assetDetails";
</style>
